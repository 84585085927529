<template>
    <div id="new-task-form">
  
      <!-- TODO: Add a submit event handler attribute to handle the form submission -->
      <!-- HINT: Remember to pass in the form data to the method so it can pass it to your API -->
      <!-- HINT: Also, you can add .prevent to the end of the attribute's key to keep the page from reloading -->

      <v-form @submit.prevent="createTask(form)">
  
        <v-container>
          <v-row>
            <v-col cols="12" xs="12" sm="6">
              <!-- TODO: Bind this input to the Text property value of the form prop -->
              <!-- Prevent text from disapearing once we click outside the text box-->
              <v-text-field label="Add a task..." prepend-icon="edit" required v-model="form.Text"></v-text-field>
  
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                <template v-slot:activator="{ on }">
  
                  <!-- TODO: Bind this input to the Date property value of the form prop -->
                  <v-text-field label="When should the task be done?" prepend-icon="event" required v-model="form.Date" v-on="on"></v-text-field>
  
                </template>
                <!-- TODO: Also bind this input to the Date property value of the form prop -->
                <v-date-picker v-model="form.Date" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="menu = false">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="4" offset-xs="4" sm="2">
              <v-btn class="mt-3" type="submit" text color="success" block>
                <v-icon left>mdi-plus</v-icon>
                Add Task
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </template>
  
  <script>
  import { getCurrentDate, formatDate } from '@/util'
  import { createTask } from '@/views/Home.vue'
  export default {
    name: 'NewTaskForm',
    props: {
      // TODO: Figure out any props that you'll need from this component's parent and add it/them here
      // Remember to add the prop as an attribute to this component's html tag
      form: {
        type: Object,
        default: () => ({
          Text: '',
          Date: formatDate(getCurrentDate()),
        }),
      },
    },
    data: () => ({
      menu: false,
    }),
    emits: ['createTask'],
    methods: {
      createTask(form) {
        this.$emit('createTask', form)
      },
    },
  }
  </script>
  
  <style scoped>
  form {
    padding: 0 1rem;
  }
  </style>  